import { html, css } from 'lit';
import '../../../../packages/neb-lit-components/src/components/neb-action-bar';

import NebPopup, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-popup/src/neb-popup';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import '../../tables/era-eob/payments/neb-table-allocated-payments';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table';

export const TITLE_TEXT = 'Reverse Claim';

export const SUBTITLE_TEXT =
  'Select a payment associated to this claim that will be reversed';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  actionBar: { id: 'action-bar' },
  subText: { id: 'sub-text' },
  table: { id: 'table' },
};

class NebPopupAllocatedPayments extends NebPopup {
  static get properties() {
    return {
      model: Array,
      __selectedIndex: Number,
    };
  }

  initState() {
    super.initState();

    this.title = TITLE_TEXT;
    this.model = [];
    this.__selectedIndex = null;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      selectItem: index => {
        this.__selectedIndex = index;
      },
      confirm: () => {
        this.onClose({ selectedIndex: this.__selectedIndex });
      },
      close: () => {
        this.onClose({ selectedIndex: null });
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content-container {
          padding: 0px;
        }

        .table {
          overflow-y: auto;
        }

        .container {
          padding: 0px;
        }

        .header {
          padding: ${CSS_SPACING};
          padding-bottom: 0px;
        }

        .content {
          margin-top: 0px;
        }
        .sub-text {
          padding-left: ${CSS_SPACING};
          padding-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div class="sub-text" id="${ELEMENTS.subText.id}">
          <span>${SUBTITLE_TEXT}</span>
        </div>
        <div class="table">
          <neb-table-allocated-payments
            id="${ELEMENTS.table.id}"
            .model="${this.model}"
            .onSelectItem="${this.handlers.selectItem}"
          ></neb-table-allocated-payments>
        </div>
      </div>
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        class="action-bar"
        confirmLabel="Select"
        cancelLabel="Cancel"
        .onCancel="${this.handlers.close}"
        .onConfirm="${this.handlers.confirm}"
        .confirmDisabled="${this.__selectedIndex === null}"
      ></neb-action-bar>
    `;
  }
}

customElements.define(
  'neb-popup-allocated-payments',
  NebPopupAllocatedPayments,
);
