export const ERA_EOB_STATUS = { OPEN: 'Open', CLOSED: 'Closed' };

export const getEraEobStatuses = ({
  hasRcmEraEobEnhancementsFF = false,
  currentStatus,
}) => {
  const statuses = Object.values({
    ...(hasRcmEraEobEnhancementsFF ? { WORKING: 'Working' } : {}),
    ...ERA_EOB_STATUS,
  });

  return currentStatus === 'Working' ? statuses.reverse() : statuses;
};

const CLAIM_STATUSES = Object.freeze({
  1: 'Processed as Primary',
  2: 'Processed as Secondary',
  3: 'Processed as Tertiary',
  4: 'Denied',
  19: 'Processed as Primary, Forwarded to Additional Payer(s)',
  20: 'Processed as Secondary, Forwarded to Additional Payer(s)',
  21: 'Processed as Tertiary, Forwarded to Additional Payer(s)',
  22: 'Reversal of Previous Payment',
  23: 'Not Our Claim, Forwarded to Additional Payer(s)',
  25: 'Predetermination Pricing Only - No Payment',
});

export const REVERSAL_CLAIM_STATUS = CLAIM_STATUSES[22];
