import '../../../../packages/neb-lit-components/src/components/neb-button-actions';
import '../../../../packages/neb-material-design/src/components/neb-loading-spinner';
import '../../../../packages/neb-lit-components/src/components/neb-text';
import '../../controls/inputs/neb-checkbox';
import './needs-attention/neb-table-claim-validation-errors';

import { html, css } from 'lit';

import NebTable, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { openOverlay } from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';
import {
  CLAIM_STATUS,
  SUBMISSION_METHOD,
} from '../../../../packages/neb-utils/claims';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../packages/neb-utils/feature-util';
import {
  centsToCurrency,
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../packages/neb-utils/formatters';
import { MODE } from '../../../../packages/neb-utils/table';
import {
  CLAIM_ERROR_DICT,
  formatClaimInfo,
} from '../../../utils/claim-errors/claim-errors';
import {
  getLocationValue,
  LOCATION_KEYS,
} from '../../../utils/locations/location-util';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  serviceDates: { selector: '[id^=cell-dateOfService-]' },
  invoiceLinks: { selector: '[id^=link-invoiceNumber-]' },
  claimLinks: { selector: '[id^=link-claimNumber-]' },
  claimIcons: { selector: '[id^=leading-icon-claimNumber-]' },
  editClaimNoteIcon: { selector: '[id^=edit-claim-note-icon-]' },
  editInvoiceNoteIcon: { selector: '[id^=edit-invoice-note-icon-]' },
  batchLinks: { selector: '[id^=link-batchId-]' },
  batchIcons: { selector: '[id^=leading-icon-batchId-]' },
  status: { selector: '[id^=cell-status-]' },
  pendingIcons: { selector: '[id^=icon-pending-]' },
  patientLinks: { selector: '[id^=link-patient-]' },
  secondaryPayers: { selector: '[id^=sec-payer-]' },
  payerLinks: { selector: '[id^=link-primaryPayerAlias-]' },
  planLinks: { selector: '[id^=link-primaryPlanName-]' },
  provider: { selector: '[id^=cell-provider-]' },
  amount: { selector: '[id^=cell-amount-]' },
  errorTable: { selector: '[id^=error-table-]' },
  claimStatusTable: { selector: '[id^=claim-status-table-]' },
  iconHidden: { selector: '.icon-hidden' },
  iconCompleted: { selector: '.icon-completed' },
  location: { selector: '.location' },
};

const TABLE_CONFIG_STATUS_CODES = [
  { key: 'spacer1', label: '', flex: css`0 0 37px` },
  { key: 'code', label: 'Code', flex: css`0.25 0 0` },
  {
    key: 'description',
    label: 'Description',
    flex: css`0.7 0 0`,
    formatter: v => html`
      <neb-text style="padding-right: 10px;">${v}</neb-text>
    `,
  },
  { key: 'statusCode', label: 'Claim Status Code', flex: css`0.7 0 0` },
  { key: 'spacer2', label: '', flex: css`1 0 0` },
];

const CLAIMS_ICON = {
  paper: 'neb:paperClaims',
  electronic: 'neb:electronicClaims',
  refresh: 'neb:refresh',
};

const HIDDEN_ICON = 'neb:visibilityOff';
const COMPLETED_ICON = 'neb:checkCircle';

const buildErrorTableConfig = (hasRcmRelease2, includeFixClaimError) => [
  {
    key: 'field',
    label: 'Field',
    flex: css`2 0 0`,
  },
  {
    key: 'value',
    label: 'Value',
    flex: css`1 0 0`,
  },
  {
    key: 'location',
    label: 'Location',
    flex: css`1 0 0`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`2 0 0`,
  },
  {
    key: 'code',
    label: 'Code',
    flex: css`1 0 0`,
  },
  ...(hasRcmRelease2
    ? [
        {
          key: 'assignedTo',
          label: 'Assigned To',
          flex: css`1.5 0 0`,
        },
        {
          key: 'completedAt',
          label: 'Completed',
          flex: css`1 0 0`,
        },
      ]
    : []),
  ...(includeFixClaimError
    ? [
        {
          key: 'fixClaimError',
          label: '',
          flex: css`1 0 0`,
          truncate: true,
        },
      ]
    : []),
];

class NebTableClaimsWorklistNeedsAttention extends NebTable {
  static get properties() {
    return {
      enablePatients: Boolean,
      totalClaims: Number,
      status: String,
      locations: Array,
      hasRcmRelease2FF: Boolean,

      __hasRcmEasyBillingNotesFF: Boolean,
      __hasRcmItdDooClaimErrorOverlayFF: Boolean,
      __hasRcmManageEncounterClaimErrorOverlayFF: Boolean,
      __hasRcmInsuranceClaimErrorOverlayFF: Boolean,
      __hasRcmPatientInfoClaimErrorOverlayFF: Boolean,
      __hasRcmPayerClaimErrorOverlayFF: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        #header {
          padding-top: ${CSS_SPACING};
          padding-bottom: 18px;
          background-color: white;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        .cell-header {
          padding: 0;
        }

        @media (max-width: 1887px) {
          #header {
            padding-top: 10px;
            padding-bottom: 11px;
          }
        }

        .cell-data[key='primaryPayerAlias'] {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-gap: 4px;
        }

        .button-actions {
          padding-top: 1px;
          width: 28px;
          font-weight: normal;
        }

        .link {
          display: grid;
          width: fit-content;
          grid-template-rows: 1fr;
          grid-template-columns: auto auto auto;
          align-items: center;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .edit-note:hover,
        .link:hover {
          opacity: 0.65;
        }

        .edit-note:focus,
        .link:focus {
          opacity: 0.65;
        }

        .link-text {
          overflow: hidden;
          text-overflow: ellipsis;
          text-decoration: underline;
        }

        .edit-note {
          cursor: pointer;
          width: 15px;
          height: 15px;
          margin-top: 3px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-hidden,
        .icon-completed {
          width: 20px;
          height: 20px;
          margin-top: 5px;
          margin-left: 10px;
        }

        .icon-hidden {
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-completed {
          fill: #8cd211;
        }

        .container-loading-spinner {
          display: flex;
          justify-content: center;
        }

        .loading-spinner {
          margin-bottom: ${CSS_SPACING};
        }

        .invoice {
          display: inline-flex;
          column-gap: 4px;
          align-items: center;
        }

        .billing-note-icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initServices();
  }

  initState() {
    super.initState();

    this.locations = [];
    this.enablePatients = false;
    this.showBulkActionMenu = true;
    this.showSelectAll = true;
    this.showExpandAll = true;
    this.totalClaims = 0;
    this.mode = MODE.EXPAND;
    this.hasRcmRelease2FF = false;

    this.__hasRcmEasyBillingNotesFF = false;
    this.__hasRcmItdDooClaimErrorOverlayFF = false;
    this.__hasRcmManageEncounterClaimErrorOverlayFF = false;
    this.__hasRcmInsuranceClaimErrorOverlayFF = false;
    this.__hasRcmPatientInfoClaimErrorOverlayFF = false;
    this.__hasRcmPayerClaimErrorOverlayFF = false;

    this.onClickLink = () => {};

    this.onChangeErrors = () => {};

    this.onRefresh = () => {};
  }

  initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.locations = locations;
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickLink: e => {
        e.stopPropagation();
        const key = e.currentTarget.getAttribute('key');
        const index = Number(e.currentTarget.getAttribute('index'));
        this.onClickLink(key, index);
      },
      openBillingNotesOverlay: e => {
        e.stopPropagation();
        const rowIndex = e.currentTarget.getAttribute('rowIndex');
        const type = e.currentTarget.getAttribute('type');

        if (type === 'claim') {
          return this.onClickLink('claimBillingNote', rowIndex);
        }
        return this.onClickLink('invoiceBillingNote', rowIndex);
      },
      changeErrors: (claimRowIndex, errors) => {
        this.onChangeErrors(claimRowIndex, errors);
      },
      openClaimErrorOverlay: async (claimRowIndex, claimErrorIndex) => {
        const claim = this.model[claimRowIndex];
        const errorCode = claim.claimValidationErrors[claimErrorIndex].code;

        const claimErrorGroupType = Object.values(CLAIM_ERROR_DICT).find(
          value => value.errorCodes.includes(errorCode),
        );

        let result;

        if (claimErrorGroupType) {
          const practiceInfo = store.getState().practiceInformation.item;

          const formattedClaimInfo = formatClaimInfo({
            claim,
            claimError: claimErrorGroupType,
            practiceInfo,
          });

          result = await openOverlay(claimErrorGroupType.overlay, {
            ...formattedClaimInfo,
            claim,
          });

          if (result) {
            this.onRefresh();
          }
        }
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();
    this.__hasRcmEasyBillingNotesFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.RCM_EASY_BILLING_NOTES,
    );

    this.__hasRcmItdDooClaimErrorOverlayFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.RCM_ITD_DOO_CLAIM_ERROR_OVERLAY,
    );

    this.__hasRcmManageEncounterClaimErrorOverlayFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.RCM_MANAGE_ENCOUNTER_CLAIM_ERROR_OVERLAY,
    );

    this.__hasRcmInsuranceClaimErrorOverlayFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.RCM_INSURANCE_CLAIM_ERROR_OVERLAY,
    );

    this.__hasRcmPatientInfoClaimErrorOverlayFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.RCM_PATIENT_INFO_CLAIM_ERROR_OVERLAY,
    );

    this.__hasRcmPayerClaimErrorOverlayFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.RCM_PAYER_CLAIM_ERROR_OVERLAY,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__locationsService.disconnect();
  }

  get __checkedRowItems() {
    return this.model.filter(row => row.checked);
  }

  get __selectedRowsIds() {
    return [];
  }

  get __selectedRows() {
    return {
      selectAll: false,
    };
  }

  __getInvoiceBillingNoteIcon(rowIndex) {
    return this.model[rowIndex].hasInvoiceBillingNote
      ? 'neb:editNote'
      : 'neb:addNote';
  }

  __renderNoteIcon(type, rowIndex) {
    if (!this.__hasRcmEasyBillingNotesFF) {
      return this.model[rowIndex].hasInvoiceBillingNote
        ? html`
            <neb-icon
              id="edit-${type}-note-icon-${rowIndex}"
              class="billing-note-icon"
              icon="neb:editNote"
              type="${type}"
              rowIndex="${rowIndex}"
              @click="${this.handlers.openBillingNotesOverlay}"
            ></neb-icon>
          `
        : '';
    }

    return html`
      <neb-icon
        id="edit-${type}-note-icon-${rowIndex}"
        class="billing-note-icon"
        icon="${this.__getInvoiceBillingNoteIcon(rowIndex)}"
        type="${type}"
        rowIndex="${rowIndex}"
        @click="${this.handlers.openBillingNotesOverlay}"
      ></neb-icon>
    `;
  }

  __getIcon(claim) {
    if (claim.submissionMethod) {
      return claim.submissionMethod === SUBMISSION_METHOD.ELECTRONIC_CLAIMS
        ? CLAIMS_ICON.electronic
        : CLAIMS_ICON.paper;
    }

    return claim.isElectronic ? CLAIMS_ICON.electronic : CLAIMS_ICON.paper;
  }

  __getCodeAndDescription(code, description) {
    if (code === 'billingProviderNPI' && description === 'Invalid value') {
      return {
        code: 'FL 33a',
        description: 'Billing Provider NPI is required',
      };
    }

    const codeAndDescription = {
      ...(code && { code }),
      ...(description && { description }),
    };

    return Object.keys(codeAndDescription).length === 0
      ? {}
      : codeAndDescription;
  }

  __getClaimStatusCodes(cs) {
    const { code, description, statusCode, statusCodeValue } = cs;

    if (
      [code, description, statusCode, statusCodeValue].every(value => !value)
    ) {
      return [];
    }

    const codeAndDescription = this.__getCodeAndDescription(code, description);
    const statusAndValue =
      statusCode && statusCodeValue ? `${statusCode} - ${statusCodeValue}` : '';

    return [{ ...codeAndDescription, statusCode: statusAndValue }];
  }

  __renderLink(key, index, value, leadingIcon, trailingIcon) {
    return html`
      <div
        id="link-${key}-${index}"
        class="link"
        key="${key}"
        index="${index}"
        @click="${this.handlers.clickLink}"
      >
        ${
          leadingIcon
            ? html`
                <neb-icon
                  id="leading-icon-${key}-${index}"
                  class="icon"
                  .icon="${leadingIcon}"
                ></neb-icon>
              `
            : ''
        } <span class="link-text">${value}</span> ${
          trailingIcon
            ? html`
                <neb-icon
                  id="trailing-icon-${key}-${index}"
                  class="icon"
                  .icon="${trailingIcon}"
                ></neb-icon>
              `
            : ''
        }
      </div>
    `;
  }

  renderExpandedRow(index, { claimValidationErrors, claimStatuses }) {
    const claimErrors = claimValidationErrors || [];
    const includeFixClaimError =
      this.__hasRcmItdDooClaimErrorOverlayFF ||
      this.__hasRcmManageEncounterClaimErrorOverlayFF ||
      this.__hasRcmInsuranceClaimErrorOverlayFF;

    if (Array.isArray(claimErrors) && claimErrors.length) {
      return html`
        <neb-table-claim-validation-errors
          id="error-table-${index}"
          class="table"
          .claimRowIndex="${index}"
          .model="${claimErrors}"
          .config="${
            buildErrorTableConfig(this.hasRcmRelease2FF, includeFixClaimError)
          }"
          .onChange="${this.handlers.changeErrors}"
          .hasRcmItdDooClaimErrorOverlay="${
            this.__hasRcmItdDooClaimErrorOverlayFF
          }"
          .hasRcmManageEncounterClaimErrorOverlay="${
            this.__hasRcmManageEncounterClaimErrorOverlayFF
          }"
          .hasRcmInsuranceClaimErrorOverlay="${
            this.__hasRcmInsuranceClaimErrorOverlayFF
          }"
          .hasRcmPatientInfoClaimErrorOverlayFF="${
            this.__hasRcmPatientInfoClaimErrorOverlayFF
          }"
          .hasRcmPayerClaimErrorOverlayFF="${
            this.____hasRcmPayerClaimErrorOverlayFF
          }"
          .onOpenClaimErrorOverlay="${this.handlers.openClaimErrorOverlay}"
        ></neb-table-claim-validation-errors>
      `;
    }

    if (claimStatuses[0]) {
      return html`
        <neb-table
          id="claim-status-table-${index}"
          class="table"
          emptyMessage="There are no claim status codes to display."
          .config="${TABLE_CONFIG_STATUS_CODES}"
          .model="${this.__getClaimStatusCodes(claimStatuses[0])}"
        ></neb-table>
      `;
    }

    return html`
      <div class="container-loading-spinner">
        <neb-loading-spinner class="loading-spinner" />
      </div>
    `;
  }

  renderCompleted(completed) {
    if (!completed) return '';

    return html`
      <neb-icon class="icon-completed" .icon="${COMPLETED_ICON}"></neb-icon>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex) {
    const row = this.model[rowIndex];
    const { key } = columnConfig;

    switch (key) {
      case 'completed':
        return this.renderCompleted(value);

      case 'hidden':
        return value
          ? html`
              <neb-icon class="icon-hidden" .icon="${HIDDEN_ICON}"></neb-icon>
            `
          : '';

      case 'locationName':
        return html`
          <div class="text">
            <div class="location">
              <neb-text
                >${
                  getLocationValue(
                    this.locations,
                    row.claimCharges[0].locationId,
                    LOCATION_KEYS.NAME,
                  )
                }</neb-text
              >
            </div>
          </div>
        `;

      case 'invoiceNumber':
        return html`
          <div class="invoice">
            ${this.__renderLink(key, rowIndex, value)}
            ${this.__renderNoteIcon('invoice', rowIndex)}
          </div>
        `;

      case 'claimNumber': {
        const icon = this.__getIcon(row);
        const { batches = [] } = row;
        const batchId = batches.length ? batches[0] : '';

        return value
          ? html`
              <div style="display: inline-flex">
                ${this.__renderLink(key, rowIndex, value, icon)}
                ${
                  this.model[rowIndex].hasClaimBillingNote
                    ? this.__renderNoteIcon('claim', rowIndex)
                    : ''
                }
              </div>
              <div>
                ${
                  batchId
                    ? this.__renderLink(
                        'batchId',
                        rowIndex,
                        batchId,
                        'neb:batch',
                      )
                    : ''
                }
              </div>
            `
          : html`
              <neb-icon
                id="leading-icon-${key}-${rowIndex}"
                class="icon"
                .icon="${icon}"
              ></neb-icon>
            `;
      }

      case 'status': {
        const { claimStatuses } = row;
        const pending =
          value && claimStatuses ? claimStatuses[0].statusPending : '';
        const validationError =
          value && claimStatuses
            ? claimStatuses[0].status === CLAIM_STATUS.VALIDATION_ERROR
            : false;

        return html`
          ${value}
          ${validationError && row.errorCount ? `(${row.errorCount})` : ''}
          ${
            pending
              ? html`
                  <neb-icon
                    id="icon-pending-${rowIndex}"
                    icon="neb:hourglassTop"
                  ></neb-icon>
                `
              : ''
          }
          </div>
        `;
      }

      case 'patient.name': {
        if (!value) return '';

        const patientName = objToName(value, DEFAULT_NAME_OPTS);
        return html`
          ${
            this.__renderLink(
              'patient',
              rowIndex,
              patientName,
              null,
              'neb:open',
            )
          }
        `;
      }

      case 'primaryPayerAlias':
        return html`
          ${
            row.paymentResponsibilityLevelCode === 'Secondary'
              ? html`
                  <span id="sec-payer-${rowIndex}">(S) </span>
                `
              : ''
          }
          ${this.__renderLink(key, rowIndex, value)}
        `;

      case 'primaryPlanName':
        return value
          ? html`
              ${this.__renderLink(key, rowIndex, value)}
            `
          : '';

      case 'provider':
        return value
          ? objToName(value.name, DEFAULT_NAME_OPTS)
          : 'No Billing Provider';

      case 'amount':
        return centsToCurrency(value);

      default:
        return value;
    }
  }
}

customElements.define(
  'neb-table-claims-worklist-needs-attention',
  NebTableClaimsWorklistNeedsAttention,
);
