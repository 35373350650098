import { css, html } from 'lit';

import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { formatEncounterSummaryData } from '../../../../packages/neb-utils/neb-encounters-util';
import '../../../../packages/neb-lit-components/src/components/encounter/neb-encounter-summary';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { buildEncounterDetails } from './claim-errors-utils';
import {
  NebOverlayClaimError,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-overlay-claim-error';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  encounterSummary: {
    id: 'encounter-summary',
  },
  encounters: {
    id: 'encounters',
  },
};

class NebOverlayClaimErrorPatientInfo extends NebOverlayClaimError {
  static get properties() {
    return {
      __summaryModel: Object,
      __encountersDetails: Array,
      __encounters: Array,
      __selectedEncounter: Object,
    };
  }

  initState() {
    super.initState();

    this.widthRatio = '60/40';

    this.__cases = [];
    this.__encounters = [];
    this.__encountersDetails = [];
    this.__summaryModel = {};
    this.__selectedEncounter = {};
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      ...this.handlers,
      onChangeEncounter: ({ event, value, index }) => {
        if (event === 'select') {
          this.__selectedEncounter = value;
          this.__setSummaryModel(this.__encountersDetails[index]);
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .encounter-summary {
          overflow: auto;
        }

        .service-date-select {
          display: flex;
          margin: 0 0 10px 0;
        }

        .left-panel-container {
          padding: 5px 10px;
        }
      `,
    ];
  }

  async firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      const {
        encounters,
        encountersDetails,
        selectedEncounter,
        selectedEncounterDetails,
      } = await buildEncounterDetails({
        patientId: this.model.patient.id,
        invoiceId: this.model.invoiceId,
      });

      this.__encounters = encounters;
      this.__selectedEncounter = selectedEncounter;
      this.__encountersDetails = encountersDetails;

      this.__setSummaryModel(selectedEncounterDetails);
    }

    super.firstUpdated();
  }

  __setSummaryModel({ encounter, chartNotes }) {
    this.__summaryModel = formatEncounterSummaryData({
      encounter,
      notes: chartNotes,
      patient: this.model.patient,
      state: store.getState(),
    });
  }

  renderLeftPanel() {
    return html`
      <div id="${ELEMENTS.leftPanel.id}" class="left-panel-container">
        <neb-select
          id="${ELEMENTS.encounters.id}"
          label="Service Date"
          class="service-date-select"
          .items="${this.__encounters}"
          .value="${this.__selectedEncounter}"
          .onChange="${this.__handlers.onChangeEncounter}"
          .layout="${this.layout}"
        ></neb-select>

        <neb-encounter-summary
          id="${ELEMENTS.encounterSummary.id}"
          class="encounter-summary"
          .model="${this.__summaryModel}"
        >
        </neb-encounter-summary>
      </div>
    `;
  }
}

customElements.define(
  'neb-overlay-claim-error-patient-info',
  NebOverlayClaimErrorPatientInfo,
);
