import { reverseClaim } from '../../../../packages/neb-api-client/src/allocation-api-client';
import { updateERAReport } from '../../../../packages/neb-api-client/src/era-report-api-client';

const removeWarnings = reportData => ({
  ...reportData,
  claims: reportData.claims.map(claim => ({
    ...claim,
    errorMessage: '',
    lineItems: claim.lineItems.map(li => ({ ...li, errorMessage: '' })),
  })),
});

export default async ({
  model: { id: eraId },
  paymentId,
  selectedPaymentId,
  claimId,
  reportData,
}) => {
  await reverseClaim(paymentId, selectedPaymentId, claimId);

  const newReportData = removeWarnings(reportData);

  await updateERAReport(eraId, { reportData: newReportData });
};
