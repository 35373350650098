import { openPopup } from '@neb/popup';

import { getPossibleMatchedLineItems } from '../../../../packages/neb-api-client/src/billing/era/era-charges-match-api-client';
import { updateERAReport } from '../../../../packages/neb-api-client/src/era-report-api-client';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { ERA_ACTIONS } from '../../../utils/era-eob';
import {
  ALL_CLAIM_CHARGE_MATCH,
  MATCHING_CHARGE_CANNOT_BE_FOUND_IN_SYSTEM,
  MATCH_CHARGE,
  MULTIPLE_CLAIM_CHARGE_MATCH,
  NO,
  NO_CLAIM_CHARGE_MATCH,
  SOME_CLAIM_CHARGE_MATCH,
  YES,
} from '../../../utils/user-message';
import { associateCharges } from '../../era';

const CLAIM_MATCH_STATUS = Object.freeze({
  ALL_MATCH: 'ALL_MATCH',
  SOME_MATCH: 'SOME_MATCH',
  NO_MATCH: 'NO_MATCH',
});

const setUpClaimMatchLineItems = (lineItems, possibleMatchedLineItems) =>
  lineItems.map(li => {
    const matchedLineItems = possibleMatchedLineItems[li.lineItemReportId];
    return !matchedLineItems.length
      ? { ...li, errorMessage: [MATCHING_CHARGE_CANNOT_BE_FOUND_IN_SYSTEM] }
      : {
          ...li,
          id: matchedLineItems.length === 1 ? matchedLineItems[0] : '',
          errorMessage:
            matchedLineItems.length > 1 ? [MULTIPLE_CLAIM_CHARGE_MATCH] : [],
        };
  });

const setUpClaimMatchDetails = ({
  claim,
  patientMedicalRecordNumber,
  possibleMatchedLineItems,
}) => ({
  ...claim,
  patientMedicalRecordNumber,
  errorMessage: '',
  lineItems: setUpClaimMatchLineItems(
    claim.lineItems,
    possibleMatchedLineItems,
  ),
});

const setUpClaimMatch = ({
  reportData,
  patientMedicalRecordNumber,
  claimReportId,
  possibleMatchedLineItems,
}) => ({
  ...reportData,
  claims: reportData.claims.map(claim => {
    const isClaimMatch = claim.claimReportId === claimReportId;
    return !isClaimMatch
      ? claim
      : setUpClaimMatchDetails({
          claim,
          patientMedicalRecordNumber,
          possibleMatchedLineItems,
        });
  }),
});

const claimMatchAction = async (data, message) => {
  const response = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
    title: `${MATCH_CHARGE}s`,
    message,
    confirmText: YES,
    cancelText: NO,
  });

  if (!response) return Promise.resolve();

  const report = setUpClaimMatch(data);
  const { eraId, reportId, possibleMatchedLineItems, claimReportId } = data;
  const lineItemIds = Object.values(possibleMatchedLineItems)
    .filter(lineItems => lineItems.length === 1)
    .flatMap(li => li);

  return Promise.all([
    updateERAReport(eraId, {
      reportData: report,
      eraAction: ERA_ACTIONS.MATCH_CLAIM,
      claimReportId,
    }),
    ...(lineItemIds.length
      ? [
          associateCharges({
            version: 2,
            lineItemIds,
            eraId,
            reportId,
          }),
        ]
      : []),
  ]);
};

const getMatchedProcedureCodes = ({
  reportData,
  possibleMatchedLineItems,
  claimReportId,
}) =>
  reportData.claims
    .find(claim => claim.claimReportId === claimReportId)
    .lineItems.filter(
      ({ lineItemReportId }) =>
        possibleMatchedLineItems[lineItemReportId].length === 1,
    )
    .map(({ procedureCode }) => procedureCode.split(' ')[0]);

const MATCH_CLAIM_ACTIONS = Object.freeze({
  [CLAIM_MATCH_STATUS.ALL_MATCH]: data =>
    claimMatchAction(data, ALL_CLAIM_CHARGE_MATCH),
  [CLAIM_MATCH_STATUS.SOME_MATCH]: data =>
    claimMatchAction(
      data,
      SOME_CLAIM_CHARGE_MATCH(getMatchedProcedureCodes(data)),
    ),
  [CLAIM_MATCH_STATUS.NO_MATCH]: data =>
    claimMatchAction(data, NO_CLAIM_CHARGE_MATCH),
});

const getClaimMatchStatus = possibleMatchedLineItems => {
  const matchedCount = Object.values(possibleMatchedLineItems).map(
    lineItems => lineItems.length,
  );

  if (matchedCount.every(mc => mc === 0)) return CLAIM_MATCH_STATUS.NO_MATCH;
  if (matchedCount.some(mc => mc === 0)) return CLAIM_MATCH_STATUS.SOME_MATCH;
  return CLAIM_MATCH_STATUS.ALL_MATCH;
};

export default async ({
  claimReportId,
  patient: { id: patientId, mrn: patientMedicalRecordNumber },
  model: { id: eraId, reportId },
  reportData,
}) => {
  const possibleMatchedLineItems = await getPossibleMatchedLineItems({
    claimReportId,
    patientId,
    eraId,
  });

  const claimMatchStatus = getClaimMatchStatus(possibleMatchedLineItems);

  return MATCH_CLAIM_ACTIONS[claimMatchStatus]({
    claimReportId,
    patientMedicalRecordNumber,
    eraId,
    possibleMatchedLineItems,
    reportData,
    reportId,
  });
};
