import { updateERAReport } from '../../../../packages/neb-api-client/src/era-report-api-client';
import { ERA_ACTIONS } from '../../../utils/era-eob';
import { associateCharges } from '../../era';

const setUpMatchReportDataClaims = ({
  reportData: { claims },
  lineItemId,
  lineItemReportId,
}) =>
  claims?.map(claim => ({
    ...claim,
    lineItems: claim.lineItems.map(lineItem => ({
      ...lineItem,
      id:
        lineItem.lineItemReportId === lineItemReportId
          ? lineItemId
          : lineItem.id,
      ...(lineItem.errorMessage && {
        errorMessage:
          lineItem.lineItemReportId === lineItemReportId
            ? []
            : lineItem.errorMessage,
      }),
    })),
  }));

const matchLineItem = ({ reportData, lineItemId, lineItemReportId }) => ({
  ...reportData,
  claims: setUpMatchReportDataClaims({
    reportData,
    lineItemId,
    lineItemReportId,
  }),
});

export default ({
  model: { id: eraId, reportId },
  reportData,
  lineItemId,
  lineItemReportId,
  claimReportId,
}) => {
  const report = matchLineItem({
    reportData,
    lineItemId,
    lineItemReportId,
    claimReportId,
  });

  return Promise.all([
    updateERAReport(eraId, {
      reportData: report,
      eraAction: ERA_ACTIONS.MATCH_CHARGE,
      lineItemReportId,
      claimReportId,
    }),
    associateCharges({
      version: 2,
      lineItemIds: [lineItemId],
      eraId,
      reportId,
    }),
  ]);
};
