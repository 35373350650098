import '../neb-button';
import '../neb-date-picker';
import '../neb-popup-header';
import '../neb-table-readonly';
import '../neb-button-actions';
import '../tables/neb-table-allocation-charges';
import '../tables/neb-table-payment-transaction';
import '../inputs/neb-textarea';
import '../neb-header';
import '../controls/neb-button-bar';
import '../controls/neb-horizontal-scroll';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { ChargeAdjustmentsTable } from '../../../../../src/components/tables/charges/neb-table-charges-adjustments';
import { ChargeResponsibilityTable } from '../../../../../src/components/tables/charges/neb-table-charges-responsibility';
import { UpdateNotificationService } from '../../../../../src/services/update-notifications';
import { getLedgerInvoiceItems } from '../../../../neb-api-client/src/invoice-api-client';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import {
  toFormatDate,
  MONTH_DAY_YEAR,
  MONTH_DAY_YEAR_TIME,
} from '../../../../neb-input/nebFormatUtils';
import { openDirtyPopup } from '../../../../neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_FONT_WEIGHT_BOLD,
  CSS_BORDER_GREY_1,
  CSS_FONT_SIZE_HEADER,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { PAYMENT_METHODS } from '../../../../neb-utils/enums';
import { centsToCurrency, objToName } from '../../../../neb-utils/formatters';
import {
  LINE_ITEM_DETAIL,
  fetchInsuranceItems,
  validateAllowedAgainstBilled,
  validateAllowedAgainstTotalOwed,
  validateOwedAmounts,
  checkForWarnings,
  viewEncounterSummary,
  viewPayment,
  viewPayerPlan,
} from '../../../../neb-utils/neb-ledger-util';
import {
  isPaymentRefunded,
  isPaymentPackageOrSubscription,
  isElectronicPayment,
  isPartiallyOrFullyAllocated,
} from '../../../../neb-utils/neb-payment-util';
import * as selectors from '../../../../neb-utils/selectors';
import { map } from '../../../../neb-utils/utils';
import { required, genRequireSelect } from '../../../../neb-utils/validators';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';

import Form, { ELEMENTS as ELEMENTS_BASE } from './neb-form';

const NO_CHARGES_TO_EDIT_OR_REMOVE_MESSAGE =
  'There are no charges for the selected parameters.';

const NO_CHARGES_TO_PREVIEW_MESSAGE =
  'There are no preview allocations for this payment.';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  header: {
    id: 'header',
  },
  subheader: {
    id: 'subheader',
  },
  buttonBar: {
    id: 'button-bar',
  },
  buttonActions: {
    id: 'button-actions',
  },
  paymentDetail: {
    id: 'payment-detail',
  },
  transactionDetail: {
    id: 'transaction-detail',
  },
  note: {
    id: 'note',
    label: 'Note',
  },
  paymentActionDetail: {
    id: 'payment-action-detail',
  },
  eRAPaymentHistory: { id: 'era-payment-history' },
  headerERAHistory: {
    id: 'era-history-header',
    title: 'ERA Payment History',
    description: '',
  },
  headerAllocatedCharges: {
    id: 'allocated-charges-header',
    title: 'Allocated Charges',
    description:
      'Select charges to edit Allocated amounts, edit Payer and Patient Owed amounts and Adjustments as needed.',
  },
  headerPreviewAllocatedCharges: {
    id: 'allocated-preview-charges-header',
    title: 'Apply Recommended Allocation',
    description:
      'Select the charges to review and apply the recommended payment allocation.',
  },
  dateOfTransactionFrom: { id: 'date-of-transaction-from' },
  dateOfTransactionTo: { id: 'date-of-transaction-to' },
  selectPatient: { id: 'select-payer' },
  selectLocation: { id: 'select-location' },
  allocationChargesTable: { id: 'allocation-charges-table' },
  removeAllocation: {
    id: 'remove-allocation',
    label: 'Remove Allocation',
  },
  hoverHorizontalScrollBar: { id: 'hover-horizontal-scroll-bar' },
  horizontalContainer: { id: 'horizontal-container' },
};

const CONFIRM_REMOVE_ALLOCATION = {
  confirmText: 'Yes',
  cancelText: 'No',
  title: 'Remove Allocation',
  message: html`
    Are you sure you want to remove the selected payments allocated amount from
    the selected charge(s)?
  `,
};

export default class NebFormPaymentView extends Form {
  static get properties() {
    return {
      __firstScrollHandler: Boolean,
      __secondScrollHandler: Boolean,
      __hoverBarVisible: Boolean,
      __minWidth: Number,
      __paymentModel: Array,
      __paymentActionDetail: Array,
      __eRAHistory: Array,
      __selectIndexes: Array,
      __menuItemsMap: Object,
      practiceUsers: Array,
      itemFilters: Object,
      locations: Array,
      patients: Array,
      paymentTypes: Array,
      adjustmentTypes: Array,
      paymentDetail: Object,
      __isAutoAllocated: Boolean,
      readonly: { type: Boolean, reflect: true },
      isPreview: { type: Boolean, reflect: true },
      hasRelationships: Boolean,
      hasFifo: Boolean,
      patientsHash: Object,
      payers: Array,
    };
  }

  static createModel() {
    return {
      paymentTransaction: [
        {
          amount: 0,
          cardSaleId: null,
          authEFT: null,
          maskedCardDescription: null,
          electronicPaymentId: null,
          electronicReferenceId: null,
          referenceId: null,
          dateOfServiceFrom: null,
          dateOfServiceTo: null,
          method: PAYMENT_METHODS.CASH,
          payer: null,
          payerPlanId: '',
          paymentType: null,
          transactionDate: null,
          patientName: null,
          patientOnline: false,
          postedById: null,
        },
      ],
      note: '',
      items: [LINE_ITEM_DETAIL],
      locations: [],
    };
  }

  createSelectors() {
    return {
      children: {
        paymentTransaction: {
          children: {
            $: {
              children: {
                amount: [
                  required(),
                  {
                    error: ' ',
                    validate: v => v > 0,
                  },
                ],
                authEFT: {
                  format: v => v || '',
                  unformat: v => (v === '' ? null : v),
                },
                method: [required()],
                paymentType: [required()],
                transactionDate: {
                  validators: [required()],
                },
                dateOfServiceFrom: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.model.paymentTransaction[0].dateOfServiceFrom
                          ? !!v
                          : true,
                    },
                  ],
                },
                dateOfServiceTo: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.model.paymentTransaction[0].dateOfServiceFrom
                          ? !!v
                          : true,
                    },
                  ],
                },
                payer: {
                  clipErrors: true,
                  clipPristine: true,
                  validators: [required(), genRequireSelect()],
                },
              },
            },
          },
        },
        note: {
          format: v => v || '',
          unformat: v => (v === '' ? null : v),
        },
        items: {
          children: {
            $: {
              children: {
                allowedAmount: selectors.currency({
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    validateAllowedAgainstBilled(
                      'The total Owed amounts must equal the Allowed amount',
                    ),

                    validateAllowedAgainstTotalOwed(
                      'The total Owed amounts must equal the Allowed amount',
                      true,
                    ),
                  ],
                }),
                adjustments: ChargeAdjustmentsTable.createSelectors(
                  this.__menuItemsMap,
                ),
                lineItemDebits: ChargeResponsibilityTable.createSelectors(
                  this.__menuItemsMap,
                  {
                    payment: this.paymentDetail,
                    preadjusted: this.__preadjusted,
                    showAllocated: true,
                  },
                ),
                patient: { unsafe: true, clipPristine: true },
                locations: selectors.multiSelect(this.locations, []),
              },
            },
          },
        },
      },
    };
  }

  constructor() {
    super();
    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.payers = [];
    this.locations = [];
    this.patients = [];
    this.readonly = false;
    this.isPreview = false;
    this.hasFifo = false;
    this.hasRelationships = false;
    this.paymentTypes = [];
    this.adjustmentTypes = [];
    this.patientsHash = {};
    this.__isAutoAllocated = false;
    this.itemFilters = {
      dateOfTransactionFrom: null,
      dateOfTransactionTo: null,
      patient: '',
      locations: [],
    };

    this.paymentDetail = {
      paymentId: '',
      amount: 0,
      eRA: {},
    };

    this.__paymentModel = [];
    this.__preadjusted = [];
    this.__preallocated = [];
    this.__selectIndexes = [];
    this.__firstScrollHandler = false;
    this.__secondScrollHandler = false;
    this.__hoverBarVisible = false;
    this.__disablePaymentOptions = false;
    this.__disablePrintReceipt = false;

    this.__menuItemsMap = {
      insurances: [],
      adjustments: [],
      paymentTypes: [],
    };

    this.__paymentActionDetail = [
      {
        date: null,
        by: {
          name: {
            last: '',
            first: '',
          },
        },
        reason: {
          code: '',
          description: '',
        },
        method: '',
        transactionId: '',
        amount: 0,
      },
    ];

    this.__eRAHistory = [];
    this.practiceUsers = [];

    this.__menuItems = Object.freeze({
      SELECT_LOCATION: {
        label: 'Select Location',
        onSelect: () => this.onPrintForLocation(),
      },
    });

    this.onDismiss = () => {};

    this.onVoid = () => {};

    this.onRefund = () => {};

    this.onPrint = () => {};

    this.onUpdateERAPayment = () => {};

    this.onEmail = () => {};

    this.onPrintForLocation = () => {};

    this.onAllocatePayment = () => {};

    this.onPreviewPaymentAllocation = () => {};

    this.onSplitPayment = () => {};

    this.onAutoAllocate = () => {};

    this.onRemoveAllocation = () => {};

    this.onItemFiltersChange = () => {};

    this.onUpdate = () => {};

    this.__notificationService = new UpdateNotificationService({
      callback: () => {
        this.rerenderPatientInfo();
      },
    });
  }

  __initHandlers() {
    this.handlers = {
      ...this.handlers,
      dismissPayment: () =>
        this.isPreview ? this.onPreviewPaymentAllocation() : this.onDismiss(),
      voidPayment: () => this.onVoid(),
      refundPayment: () => this.onRefund(),
      allocatePayment: () => this.onAllocatePayment(),
      previewPaymentAllocation: () => this.onPreviewPaymentAllocation(),
      splitPayment: () => this.onSplitPayment(),
      autoAllocate: () => {
        if (!this.__isAutoAllocated) this.onAutoAllocate();
        this.__isAutoAllocated = true;
      },
      printPayment: () => this.onPrint(),
      emailPayment: () => this.onEmail(),
      edit: async () => {
        const result = await openOverlay(
          OVERLAY_KEYS.UPDATE_PAYER_PAYMENT,
          this.paymentDetail,
        );

        if (result) await this.onUpdate();
      },
      checkItem: (index, checked) => {
        this.__selectIndexes.splice(index, 1, !checked);
        this.__selectIndexes = [...this.__selectIndexes];

        if (checked) this.__revertRow(index);
      },

      addItem: (path, index = -1) => {
        this.formService.addItem(`items.${path}`, index);
      },

      removeItem: (path, index) => {
        this.formService.removeItem(`items.${path}`, index);
      },

      removeAllocation: async () => {
        const confirmed = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          CONFIRM_REMOVE_ALLOCATION,
        );

        if (confirmed) {
          const lineItems = this.formService.build().items;
          const selectedLineItems = lineItems.filter(
            (_, index) => this.__selectIndexes[index],
          );

          this.onRemoveAllocation(selectedLineItems);
        }
      },

      blur: e => {
        const segments = ['items', ...e.name.split('.')];
        const key = segments[segments.length - 1];

        switch (key) {
          case 'codePaymentId':
            this.__blurCodePaymentId(segments);
            break;

          case 'allowedAmount':
            this.__blurAllowedAmount(segments);
            break;

          case 'amount':
            if (segments[segments.length - 2] === 'debit') {
              this.__blurDebitAmount(segments);
            }

            if (segments[segments.length - 3] === 'adjustments') {
              this.__blurAdjustmentsAmount(segments);
            }
            break;

          case 'codeId':
            if (segments[segments.length - 3] === 'adjustments') {
              this.__blurAdjustmentsCode(segments);
            }
            break;

          default:
        }

        this.formService.validateKey(segments, true);
      },

      change: e => {
        this.formService.apply(e.name, e.value);

        const segments = e.name.split('.');
        const section = segments[segments.length - 1];

        if (
          section === 'patientInsuranceId' &&
          e.value &&
          e.value.data &&
          e.value.data.payerPlanId
        ) {
          const payerId = e.value.data.payerPlanId;
          const payerIdPath = [
            ...segments.slice(0, segments.length - 1),
            'debit',
            'payerId',
          ].join('.');

          this.formService.apply(payerIdPath, payerId);
        }
      },

      itemsFilterChange: async ({ name, value }) => {
        const originalFilters = this.itemFilters;

        if (!equal(this.itemFilters[name], value)) {
          this.itemFilters = {
            ...this.itemFilters,
            [name]: value,
          };

          if (!this.paymentDetail.id) return;
          const accepted = this.__dirty ? await openDirtyPopup() : true;

          if (accepted) this.onItemFiltersChange(this.itemFilters);
          else this.itemFilters = originalFilters;

          if (name === 'dateOfTransactionFrom') {
            this.__updateDateOfTransactionFromSelectable();
          }

          if (name === 'dateOfTransactionTo') {
            this.__updateDateOfTransactionToSelectable();
          }
        }
      },
      viewEncounterSummary: (encounterId, appointmentTypeId) =>
        viewEncounterSummary({
          encounterId,
          appointmentTypeId,
          patientId: this.patientId,
          isDirty: this.__dirty,
          formIsValid: this.formService.validate(),
          onLoad: this.onUpdate,
          onSave: this.handlers.save,
        }),
      viewPayment: payment =>
        viewPayment({
          payment,
          isDirty: this.__dirty,
          formIsValid: this.formService.validate(),
          onLoad: this.onUpdate,
          onSave: this.handlers.save,
        }),
      viewPayerPlan: payerPlan =>
        viewPayerPlan({
          payerPlan,
          isDirty: this.__dirty,
          formIsValid: this.formService.validate(),
          onLoad: this.onUpdate,
          onSave: this.handlers.save,
        }),

      viewCharge: async (id, invoiceId, patientId) => {
        const lineItemIds = invoiceId
          ? (await getLedgerInvoiceItems(invoiceId)).data.map(li => li.id)
          : [id];

        await openOverlay(OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES, {
          patient: this.patientsHash[patientId],
          lineItemIds,
          selectedIds: [],
        });

        this.reload();
      },

      navigateToPatientLedger: () => {
        navigate(`/patients/${this.patientId}/ledger/activity/encounters`);
      },

      refreshPayer: () => this.onUpdate(),

      setMinWidth: minWidth => {
        this.__minWidth = minWidth;
      },

      horizontalScroll: e => {
        if (this.__firstScrollHandler) {
          this.__firstScrollHandler = false;
          return;
        }

        this.__secondScrollHandler = true;

        this.__elements.hoverHorizontalScrollBar.setScrollLeft(
          e.currentTarget.scrollLeft,
        );
      },

      hoverBarScroll: scrollLeft => {
        if (this.__secondScrollHandler) {
          this.__secondScrollHandler = false;
          return;
        }

        this.__firstScrollHandler = true;

        this.__elements.container.scrollLeft = scrollLeft;
      },

      resizeWindow: () => {
        this.__hoverBarVisible = this.__setHoverBarVisibility();
      },

      save: async () => {
        const valid = this.formService.validate();

        const onlyWarnings = await checkForWarnings(this.errors);

        if (valid || onlyWarnings) {
          const rawModel = this.formService.build();
          const model = map(rawModel, (keyPath, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          this.__saving = true;
          this.onSave(model, this.__selectIndexes);
        }
      },

      updateERAPayment: async () => {
        const allocatedAmount = this.__preallocated.reduce(
          (sum, allocated) => sum + allocated,
          0,
        );
        const lowerBound = Math.max(allocatedAmount, 0);
        const updatedPayment = await openPopup(
          POPUP_RENDER_KEYS.UPDATE_ERA_PAYMENT,
          {
            amount: this.paymentDetail.amount,
            lowerBound,
            upperBound: this.paymentDetail.eRA.originalAmount,
            warn: this.paymentDetail.eRA.warn,
          },
        );

        if (updatedPayment) {
          this.onUpdateERAPayment(updatedPayment);
        }
      },

      selectAll: () => {
        this.__selectIndexes = this.__selectIndexes.map(() => true);
      },

      deselectAll: () => {
        this.__selectIndexes = this.__selectIndexes.map((_, index) => {
          this.__revertRow(index);
          return false;
        });
      },
    };

    this.__updateDateOfTransactionFromSelectable();
    this.__updateDateOfTransactionToSelectable();
  }

  __updateDateOfTransactionFromSelectable() {
    this.handlers.dateOfTransactionFromSelectable = date =>
      (this.itemFilters.dateOfTransactionTo === null ||
        date.isSameOrBefore(this.itemFilters.dateOfTransactionTo)) &&
      date.isSameOrBefore(parseDate().endOf('day'));
  }

  __updateDateOfTransactionToSelectable() {
    this.handlers.dateOfTransactionToSelectable = date =>
      (this.itemFilters.dateOfTransactionFrom === null ||
        date.isSameOrAfter(this.itemFilters.dateOfTransactionFrom)) &&
      date.isSameOrBefore(parseDate().endOf('day'));
  }

  __blurAdjustmentsCode(segments) {
    const amountPath = [...segments.slice(0, segments.length - 1), 'amount'];

    this.formService.unsetPristine(amountPath);
    this.formService.validateKey(amountPath, true);
  }

  __blurAdjustmentsAmount(segments) {
    const codeIdPath = [...segments.slice(0, segments.length - 1), 'codeId'];

    this.formService.unsetPristine(codeIdPath);
    this.formService.validateKey(codeIdPath, true);

    validateOwedAmounts(segments.slice(0, 2), this.state, this.formService);
  }

  __blurAllowedAmount(segments) {
    validateOwedAmounts(segments.slice(0, 2), this.state, this.formService);
  }

  __blurDebitAmount(segments) {
    const lineItemDebitIndex = Number(segments[segments.length - 3]);

    const lineItemIndex = Number(segments[1]);

    const patient = !this.state.items[lineItemIndex].lineItemDebits[
      lineItemDebitIndex
    ].debit.payerId;

    if (patient) {
      const codePaymentIdPath = [
        ...segments.slice(0, segments.length - 2),
        'codePaymentId',
      ];

      this.formService.unsetPristine(codePaymentIdPath);
      this.formService.validateKey(codePaymentIdPath, true);
    }

    validateOwedAmounts(segments.slice(0, 2), this.state, this.formService);
  }

  __blurCodePaymentId(segments) {
    const lineItemDebitIndex = Number(segments[segments.length - 2]);

    const lineItemIndex = Number(segments[1]);

    const patient = !this.state.items[lineItemIndex].lineItemDebits[
      lineItemDebitIndex
    ].debit.payerId;

    if (patient) {
      const debitAmountPath = [
        ...segments.slice(0, segments.length - 1),
        'debit',
        'amount',
      ];

      this.formService.unsetPristine(debitAmountPath);
      this.formService.validateKey(debitAmountPath, true);
    }
  }

  __addOrRemoveRow(selectedIndex, li, propName) {
    const origLength = this.formService.__initialState.items[selectedIndex][
      propName
    ].length;
    const modLength = li[propName].length;

    const diffLength = modLength - origLength;
    const action = diffLength && (diffLength > 0 ? 'removeItem' : 'addItem');

    Array(Math.abs(diffLength))
      .fill()
      .forEach(() =>
        this.formService[action](`items.${selectedIndex}.${propName}`),
      );
  }

  __getMenuItems() {
    return [this.__menuItems.SELECT_LOCATION];
  }

  __revertDebit(selectedIndex, initialSelectLineItem) {
    initialSelectLineItem.lineItemDebits.forEach((lid, index) => {
      const basePath = `items.${selectedIndex}.lineItemDebits.${index}`;
      const initialLid = initialSelectLineItem.lineItemDebits[index];

      this.formService.apply(
        `${basePath}.codePaymentId`,
        initialLid.codePaymentId,
      );

      this.formService.apply(
        `${basePath}.patientInsuranceId`,
        initialLid.patientInsuranceId,
      );

      this.formService.apply(
        `${basePath}.debit.payerId`,
        initialLid.debit.payerId,
      );

      this.formService.apply(
        `${basePath}.debit.allocated`,
        initialLid.debit.allocated,
      );

      this.formService.apply(
        `${basePath}.debit.amount`,
        initialLid.debit.amount,
      );
    });
  }

  __revertAdjustment(selectedIndex, initialSelectLineItem) {
    initialSelectLineItem.adjustments.forEach((a, index) => {
      const basePath = `items.${selectedIndex}.adjustments.${index}`;
      const selectAdjustment = initialSelectLineItem.adjustments[index];

      this.formService.apply(`${basePath}.amount`, selectAdjustment.amount);
      this.formService.apply(`${basePath}.codeId`, selectAdjustment.codeId);
    });
  }

  __revertAllowed(selectedIndex, initialSelectLineItem) {
    const path = `items.${selectedIndex}.allowedAmount`;

    this.formService.apply(path, initialSelectLineItem.allowedAmount);
  }

  __revertRow(selectedIndex) {
    if (this.formService.isDirty) {
      const li = this.state.items[selectedIndex];

      this.__addOrRemoveRow(selectedIndex, li, 'lineItemDebits');
      this.__addOrRemoveRow(selectedIndex, li, 'adjustments');

      const initialSelectLineItem = this.formService.__initialState.items[
        selectedIndex
      ];

      this.__revertDebit(selectedIndex, initialSelectLineItem);
      this.__revertAdjustment(selectedIndex, initialSelectLineItem);
      this.__revertAllowed(selectedIndex, initialSelectLineItem);

      this.formService.validate();
    }
  }

  __genPaymentActions() {
    const electronic = isElectronicPayment(this.paymentDetail);
    const disableAllocate = this.paymentDetail.status !== 'Unallocated';
    const hasERA = this.paymentDetail.eRA;
    const hasSplit = !!this.paymentDetail.parentPaymentId;
    const isPayerPayment = !!this.paymentDetail.payerPlanId;
    const hasSomeAllocation = isPartiallyOrFullyAllocated(
      this.__paymentModel[0],
    );

    const actionable = ['Allocated', 'Unallocated'].includes(
      this.paymentDetail.status,
    );
    const hasEClaimInfo = !!(hasERA && this.paymentDetail.eRA.eClaimInfo);

    const disablePreview =
      this.paymentDetail.paymentMethod !== 'ERA' ||
      !isPayerPayment ||
      !hasEClaimInfo ||
      disableAllocate;

    const disableSplit = !hasSplit && disableAllocate;

    const disableRefund = hasERA || disableAllocate || hasSomeAllocation;
    const disableVoid = electronic || !actionable || hasSplit;

    const items = [
      {
        name: 'void',
        label: 'Void Payment',
        icon: 'clear',
        onClick: this.handlers.voidPayment,
        disabled: disableVoid || this.readonly || this.formService.isDirty,
      },
      {
        name: 'refund',
        label: 'Refund Payment',
        icon: 'refund',
        onClick: this.handlers.refundPayment,
        disabled: disableRefund || this.readonly || this.formService.isDirty,
      },
      {
        name: 'previewPaymentAllocation',
        label: 'Preview Payment Allocation',
        icon: 'preview',
        onClick: this.handlers.previewPaymentAllocation,
        disabled: disablePreview || this.readonly || this.formService.isDirty,
      },
      {
        name: 'allocate',
        label: 'Allocate Payment',
        icon: 'allocate',
        onClick: this.handlers.allocatePayment,
        disabled: disableAllocate || this.readonly || this.formService.isDirty,
      },
      ...(this.hasFifo
        ? [
            {
              name: 'auto',
              label: 'Auto Allocate',
              icon: 'flashAuto',
              onClick: this.handlers.autoAllocate,
              disabled:
                disableAllocate ||
                this.readonly ||
                this.formService.isDirty ||
                this.__isAutoAllocated ||
                isPayerPayment,
            },
          ]
        : []),
      {
        name: 'split',
        label: 'Split Payment',
        icon: 'split',
        onClick: this.handlers.splitPayment,
        disabled: disableSplit || this.readonly || this.formService.isDirty,
      },
    ];

    const emailReceipt = {
      name: 'email-receipt',
      label: 'Email Receipt',
      icon: 'email',
      onClick: this.handlers.emailPayment,
      disabled: (!!this.paymentDetail.payerPlanId && !hasERA) || hasSplit,
    };

    this.__disablePrintReceipt =
      (!!this.paymentDetail.payerPlanId && !hasERA) || (hasSplit && !hasERA);

    const printReceipt = {
      name: 'receipt',
      label: `${!hasERA ? 'Print Receipt' : 'ERA Report'}`,
      icon: 'receipt',
      onClick: this.handlers.printPayment,
      disabled: this.__disablePrintReceipt,
    };

    items.push(emailReceipt);
    items.push(printReceipt);

    if (
      this.paymentDetail.eRA &&
      (this.paymentDetail.eRA.warn ||
        this.paymentDetail.eRA.unmatchedAmount > 0)
    ) {
      items.push({
        name: 'update',
        label: 'Update Payment',
        icon: 'edit',
        onClick: this.handlers.updateERAPayment,
        warn: true,
      });
    }

    return items;
  }

  __toggleRemoveAllocation() {
    return !this.__selectIndexes.some(index => index);
  }

  async __getPatientInsurances() {
    const patients = this.patients.filter(p => p.data.id);
    const insurances = await Promise.all(
      patients.length ? patients.map(p => fetchInsuranceItems(p.data.id)) : [],
    );

    return patients.flatMap((p, index) =>
      insurances[index].map(insurance => ({
        ...insurance,
        patientId: p.data.id,
      })),
    );
  }

  async rerenderPatientInfo() {
    const patient = await patientApiClient.fetchOne(
      this.paymentDetail.patientId,
    );

    const patientName = objToName(patient.name, {
      reverse: true,
      middleInitial: true,
    });

    this.state.paymentTransaction.forEach((payment, index) => {
      this.formService.apply(
        `paymentTransaction.${index}.patientName`,
        patientName,
      );

      if (!payment.payerId) {
        this.formService.apply(
          `paymentTransaction.${index}.payer`,
          patientName,
        );
      }
    });
  }

  async getMenuItemsMap() {
    const patientInsurances = await this.__getPatientInsurances();

    return {
      insurances: patientInsurances,
      adjustments: this.adjustmentTypes,
      paymentTypes: this.paymentTypes,
    };
  }

  __setHoverBarVisibility() {
    const tableHeaderSpace = 40;
    return (
      this.__elements.container &&
      this.__elements.container.getBoundingClientRect().top + tableHeaderSpace <
        document.body.getBoundingClientRect().bottom
    );
  }

  setYOffset(offset = 0) {
    this.__hoverBarVisible = this.__setHoverBarVisibility();

    if (this.__hoverBarVisible) {
      this.__elements = {
        ...this.__elements,
        hoverHorizontalScrollBar: this.shadowRoot.getElementById(
          ELEMENTS.hoverHorizontalScrollBar.id,
        ),
      };
    }

    if (this.__elements.hoverHorizontalScrollBar) {
      this.__elements.hoverHorizontalScrollBar.setScrollOffsetTop(offset);
    }
  }

  async load() {
    this.__menuItemsMap = await this.getMenuItemsMap();
  }

  connectedCallback() {
    super.connectedCallback();

    this.__notificationService.connect();

    window.addEventListener('resize', this.handlers.resizeWindow);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__notificationService.disconnect();
    window.removeEventListener('resize', this.handlers.resizeWindow);
  }

  __setPresetValues() {
    this.__selectIndexes = this.model.items.map(() => this.isPreview);

    if (!this.isPreview) {
      this.__preallocated = this.model.items.map(li =>
        li.lineItemDebits
          ? li.lineItemDebits.reduce((sum, lid) => sum + lid.debit.allocated, 0)
          : 0,
      );

      this.__preadjusted = this.model.items.map(li =>
        li.adjustments
          ? li.adjustments.reduce((sum, adj) => sum + adj.amount, 0)
          : 0,
      );
    }
  }

  update(changedProps) {
    if (changedProps.has('paymentDetail') && this.paymentDetail) {
      this.__paymentModel = [
        {
          paymentAmount: this.paymentDetail.amount,
          refunded: 0,
          allocated: !(
            this.paymentDetail.voidedAt || this.paymentDetail.refundedAt
          )
            ? this.paymentDetail.amount - this.paymentDetail.available
            : 0,
          split: 0,
          available: this.paymentDetail.available,
          recommendedAllocated: this.paymentDetail.recommendedAllocated
            ? this.paymentDetail.recommendedAllocated
            : 0,
          paymentMethod: this.paymentDetail.paymentMethod,
          postedById: this.paymentDetail.postedById,
        },
      ];
    }

    if (changedProps.has('model') && this.model.items) {
      this.__setPresetValues();

      this.__elements = {
        hoverHorizontalScrollBar: this.shadowRoot.getElementById(
          ELEMENTS.hoverHorizontalScrollBar.id,
        ),
        container: this.shadowRoot.getElementById(
          ELEMENTS.horizontalContainer.id,
        ),
      };

      this.setYOffset();

      this.__notificationService.update({
        patient: { id: this.paymentDetail.patientId },
      });
    }

    super.update(changedProps);
  }

  async updated(changedProps) {
    if (changedProps.has('paymentDetail') && this.paymentDetail) {
      if (this.paymentDetail.voidedAt) {
        const { voidPayment } = this.paymentDetail;

        this.__paymentActionDetail = [
          {
            date: this.paymentDetail.voidedAt,
            by: this.practiceUsers.find(u => u.id === voidPayment.voidedById),
            reason: voidPayment.codeRefund,
            amount: this.paymentDetail.amount,
          },
        ];
      }

      if (isPaymentRefunded(this.paymentDetail)) {
        const { refundPayment } = this.paymentDetail;

        this.__paymentActionDetail = [
          {
            date: refundPayment.updatedAt,
            by: this.practiceUsers.find(
              u => u.id === refundPayment.refundedById,
            ),
            reason: refundPayment.codeRefund,
            method: refundPayment.refundMethod,
            transactionId: refundPayment.cardRefundId || '-',
            amount: this.paymentDetail.amount,
          },
        ];
      }

      if (this.paymentDetail.eRA) {
        this.__eRAHistory = [...this.paymentDetail.eRA.history];
      }
    }

    if (changedProps.has('patients') && this.patients.length > 1) {
      this.__menuItemsMap = await this.getMenuItemsMap();
      this.build();
    }

    if (this.formService.isDirty && !this.__disablePaymentOptions) {
      this.__disablePaymentOptions = true;
    } else if (!this.formService.isDirty && this.__disablePaymentOptions) {
      this.__disablePaymentOptions = false;
    }

    super.updated(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .subheader {
          margin: 0 ${CSS_SPACING} ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .table-spacer {
          padding-bottom: ${CSS_SPACING};
        }

        .note-container {
          display: flex;
          padding: ${CSS_SPACING};
        }

        .note {
          flex: 1 0 0;
          max-height: 78px;
        }

        .note-spacer {
          flex: 1 0 0;
        }

        .container-filter {
          padding: ${CSS_SPACING};
          display: grid;
          grid-template-columns: repeat(4, 3fr);
          grid-gap: ${CSS_SPACING};
          width: 100%;
          padding-top: ${CSS_SPACING};
          z-index: 2;
        }

        .history-header {
          margin: ${CSS_SPACING} 0px;
        }

        .history {
          flex: 1 0 0;
          border: ${CSS_BORDER_GREY_1};
          border-radius: 5px;
        }

        history-table: {
          margin-top: 20px;
        }

        .horizontal-scroll-container {
          overflow-x: scroll;
          scrollbar-width: none;

          /* Necessary styles for allowing popover to not intersect with top of div */
          padding-top: 220px;
          margin-top: -220px;
        }

        .horizontal-scroll-container::-webkit-scrollbar {
          width: 0;
        }

        .field {
          width: 100%;
        }

        .padding-bottom {
          padding-bottom: 130px;
        }

        .content {
          position: relative;
        }

        .container-totals {
          display: grid;
          grid-template-columns: auto auto 3fr;
          grid-gap: ${CSS_SPACING};
          width: 100%;
          padding: ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_1};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          margin-bottom: ${CSS_SPACING};
        }

        .content-padding {
          padding: ${CSS_SPACING};
        }

        .button-remove-allocation {
          width: max-content;
          padding-left: ${CSS_SPACING};
          z-index: 1;
        }

        .buttons {
          display: flex;
          align-items: center;
        }

        .buttons-bar {
          padding-left: 20px;
        }

        .button-actions {
          width: 20px;
        }
      `,
    ];
  }

  __getPaymentConfig() {
    return [
      {
        key: 'paymentAmount',
        label: 'Payment Amount',
        truncate: true,
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
      {
        truncate: true,
        key: 'allocated',
        label: 'Allocated',
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
      ...(this.isPreview
        ? [
            {
              truncate: true,
              key: 'recommendedAllocated',
              label: 'Recommended Allocated',
              flex: css`1 0 0`,
              formatter: amount => centsToCurrency(amount),
            },
          ]
        : []),
      {
        truncate: true,
        key: 'available',
        label: 'Available',
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
    ];
  }

  __getERAHistoryConfig() {
    return [
      {
        truncate: true,
        key: 'updatedAt',
        label: 'Date and Time',
        flex: css`1 0 0`,
        formatter: value =>
          value ? parseDate(value).format(MONTH_DAY_YEAR_TIME) : '',
      },
      {
        key: 'originalAmount',
        label: 'Previous Amount',
        truncate: true,
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
      {
        key: 'amount',
        label: 'Updated Amount',
        truncate: true,
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
      {
        key: 'warn',
        label: 'Warning on Ledger',
        truncate: true,
        flex: css`1 0 0`,
        formatter: warn => (warn ? 'Yes' : 'No'),
      },
    ];
  }

  __getPaymentActionDetailConfig() {
    const actionStr = isPaymentRefunded(this.paymentDetail)
      ? 'Refunded'
      : 'Voided';

    const otherActionStr = isPaymentRefunded(this.paymentDetail)
      ? 'Refund'
      : 'Void';

    return [
      {
        key: 'date',
        label: `${actionStr} Date`,
        flex: css`1 0 0`,
        formatter: value => (value ? toFormatDate(value, MONTH_DAY_YEAR) : ''),
      },
      {
        key: 'by',
        label: `${actionStr} By`,
        flex: css`1 0 0`,
        formatter: user => `${user.name.last}, ${user.name.first}`,
      },
      {
        key: 'reason',
        label: `${otherActionStr} Reason`,
        flex: css`1 0 0`,
        formatter: (codeRefund = {}) =>
          `${codeRefund.code} - ${codeRefund.description}`,
      },
      ...(isPaymentRefunded(this.paymentDetail)
        ? [
            {
              key: 'method',
              label: `${otherActionStr} Method`,
              flex: css`1 0 0`,
            },
          ]
        : []),
      ...(isPaymentRefunded(this.paymentDetail) &&
      this.paymentDetail.refundPayment.cardRefundId
        ? [
            {
              key: 'transactionId',
              label: `${otherActionStr} Transaction ID`,
              flex: css`1 0 0`,
            },
          ]
        : []),
      {
        key: 'amount',
        label: `${actionStr} Amount`,
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
    ];
  }

  __renderDropdownMenu() {
    return !this.paymentDetail.eRA
      ? html`
            <neb-button-actions
              id="${ELEMENTS.buttonActions.id}"
              class="button button-actions"
              align="right"
              maxVisibleItems="10"
              vertical
              .value="${this.__getMenuItems()}"
              iconHeight='20px'
              iconWidth='20px'
              ?disabled="${this.__disablePrintReceipt}"
            ></neb-button-actions>
          </div>
        `
      : '';
  }

  __renderButtonBar() {
    return html`
      <neb-button-bar
        id="${ELEMENTS.buttonBar.id}"
        class="buttons-bar"
        .config="${this.__genPaymentActions()}"
      ></neb-button-bar>
    `;
  }

  __renderHeaderButtons() {
    return html`
      <div class="buttons">
        ${this.__renderButtonBar()} ${this.__renderDropdownMenu()}
      </div>
    `;
  }

  __renderHeader() {
    return html`
      ${
        this.isPreview
          ? html`
              <neb-popup-header
                id="${ELEMENTS.header.id}"
                class="header"
                title="Recommended Payer Payment Allocation"
                showCancelButton
                .onCancel="${this.handlers.dismissPayment}"
              ></neb-popup-header>

              <h2 id="${ELEMENTS.subheader.id}" class="subheader">
                Payment ID ${this.paymentDetail.paymentNumber}
              </h2>
            `
          : html`
              <neb-popup-header
                id="${ELEMENTS.header.id}"
                class="header"
                .title="Payment ID ${this.paymentDetail.paymentNumber}"
                .onCancel="${this.handlers.dismissPayment}"
                showCancelButton
              ></neb-popup-header>

              ${this.__renderHeaderButtons()}
            `
      }
    `;
  }

  __renderLocationsSelect() {
    return html`
      <neb-select
        id="${ELEMENTS.selectLocation.id}"
        name="locations"
        class="field"
        label="Locations"
        .items="${this.locations}"
        .value="${this.itemFilters.locations}"
        .onChange="${this.handlers.itemsFilterChange}"
        multiSelect
      >
      </neb-select>
    `;
  }

  __renderPaymentDetails() {
    const isVoidedOrRefunded =
      this.paymentDetail.voidedAt || isPaymentRefunded(this.paymentDetail);

    const readOnlyDetails = isVoidedOrRefunded || this.isPreview;

    const readOnlyNotes =
      isVoidedOrRefunded ||
      isPaymentPackageOrSubscription(this.paymentDetail.paymentTypeCode) ||
      isPartiallyOrFullyAllocated(this.__paymentModel[0]);

    return html`
      ${
        isVoidedOrRefunded
          ? html`
              <neb-table-readonly
                id="${ELEMENTS.paymentActionDetail.id}"
                .model="${this.__paymentActionDetail}"
                .config="${this.__getPaymentActionDetailConfig()}"
                .layout="${this.layout}"
              ></neb-table-readonly>
              <div class="table-spacer"></div>
            `
          : ''
      }

      <neb-table-readonly
        id="${ELEMENTS.paymentDetail.id}"
        .model="${this.__paymentModel}"
        .config="${this.__getPaymentConfig()}"
        .layout="${this.layout}"
      ></neb-table-readonly>

      <div class="table-spacer"></div>

      <neb-table-payment-transaction
        id="${ELEMENTS.transactionDetail.id}"
        name="paymentTransaction"
        .layout="${this.layout}"
        .paymentDetail="${this.paymentDetail}"
        .paymentTypes="${this.paymentTypes}"
        .practiceUsers="${this.practiceUsers}"
        .model="${this.state.paymentTransaction}"
        .errors="${this.errors.paymentTransaction}"
        .onChange="${this.handlers.change}"
        .onEdit="${this.handlers.edit}"
        .onSelectPatient="${this.handlers.navigateToPatientLedger}"
        .onPayerTransactionUpdate="${this.handlers.refreshPayer}"
        ?readOnlyDetails="${readOnlyDetails}"
      ></neb-table-payment-transaction>

      <div class="table-spacer"></div>

      ${
        this.__eRAHistory.length
          ? html`
              <div class="note-container">
                <div class="history">
                  <neb-header
                    id="${ELEMENTS.headerERAHistory.id}"
                    class="history-header"
                    .label="${ELEMENTS.headerERAHistory.title}"
                    .description=""
                  ></neb-header>
                  <neb-table-readonly
                    id="${ELEMENTS.eRAPaymentHistory.id}"
                    class="history-table"
                    .model="${this.__eRAHistory}"
                    .config="${this.__getERAHistoryConfig()}"
                    .layout="${this.layout}"
                  ></neb-table-readonly>
                </div>
                <div class="note-spacer"></div>
              </div>
            `
          : ''
      }
      ${
        this.isPreview
          ? ''
          : html`
              <div class="note-container">
                <neb-textarea
                  id="${ELEMENTS.note.id}"
                  class="note"
                  name="note"
                  label="${ELEMENTS.note.label}"
                  maxLength="50"
                  .value="${this.state.note}"
                  .onChange="${this.handlers.change}"
                  ?disabled="${readOnlyNotes}"
                ></neb-textarea>
                <div class="note-spacer"></div>
              </div>
            `
      }
    `;
  }

  __renderEditAllocation() {
    return html`
      <neb-button
        id="${ELEMENTS.removeAllocation.id}"
        class="button-remove-allocation"
        role="outline"
        label="${ELEMENTS.removeAllocation.label}"
        ?disabled="${this.__toggleRemoveAllocation()}"
        .onClick="${this.handlers.removeAllocation}"
      ></neb-button>

      <div
        id="${ELEMENTS.horizontalContainer.id}"
        class="horizontal-scroll-container"
        @scroll="${this.handlers.horizontalScroll}"
      >
        <neb-table-allocation-charges
          id="${ELEMENTS.allocationChargesTable.id}"
          class="padding-bottom"
          name="items"
          emptyMessage="${NO_CHARGES_TO_EDIT_OR_REMOVE_MESSAGE}"
          .model="${this.state.items}"
          .patientId="${this.paymentDetail.patientId}"
          .itemsMap="${this.__menuItemsMap}"
          .preallocated="${this.__preallocated}"
          .errors="${this.errors.items}"
          .hasErrors="${this.formService.hasErrors}"
          .selectIndexes="${this.__selectIndexes}"
          .onChange="${this.handlers.change}"
          .onItemCheck="${this.handlers.checkItem}"
          .onAdd="${this.handlers.addItem}"
          .onRemove="${this.handlers.removeItem}"
          .onSelectEncounter="${this.handlers.viewEncounterSummary}"
          .onSelectCharge="${this.handlers.viewCharge}"
          .onBlur="${this.handlers.blur}"
          .onMinWidthUpdated="${this.handlers.setMinWidth}"
          .onSelectAll="${this.handlers.selectAll}"
          .onDeselectAll="${this.handlers.deselectAll}"
          .allocatableId="${
            this.paymentDetail ? this.paymentDetail.payerPlanId : null
          }"
          .showPatient="${
            this.hasRelationships || this.paymentDetail.patientId === null
          }"
          .onSelectPayerPlan="${this.handlers.viewPayerPlan}"
          .onClickPaymentId="${this.handlers.viewPayment}"
        ></neb-table-allocation-charges>
      </div>
    `;
  }

  __renderPreviewAllocation() {
    return html`
      <div
        id="${ELEMENTS.horizontalContainer.id}"
        class="horizontal-scroll-container"
        @scroll="${this.handlers.horizontalScroll}"
      >
        <neb-table-allocation-charges
          id="${ELEMENTS.allocationChargesTable.id}"
          class="padding-bottom"
          name="items"
          emptyMessage="${NO_CHARGES_TO_PREVIEW_MESSAGE}"
          .model="${this.state.items}"
          .patientId="${this.paymentDetail.patientId}"
          .itemsMap="${this.__menuItemsMap}"
          .preallocated="${this.__preallocated}"
          .errors="${this.errors.items}"
          .hasErrors="${this.formService.hasErrors}"
          .selectIndexes="${this.__selectIndexes}"
          .onChange="${this.handlers.change}"
          .onItemCheck="${this.handlers.checkItem}"
          .onAdd="${this.handlers.addItem}"
          .onRemove="${this.handlers.removeItem}"
          .onSelectEncounter="${this.handlers.viewEncounterSummary}"
          .onSelectCharge="${this.handlers.viewCharge}"
          .onBlur="${this.handlers.blur}"
          .onMinWidthUpdated="${this.handlers.setMinWidth}"
          .onSelectAll="${this.handlers.selectAll}"
          .onDeselectAll="${this.handlers.deselectAll}"
          .allocatableId="${
            this.paymentDetail ? this.paymentDetail.payerPlanId : null
          }"
          ?disabled="${![this.paymentDetail].length}"
          .showPatient="${
            this.paymentDetail && this.paymentDetail.patientId !== null
          }"
          .payers="${this.payers}"
          .onSelectPayerPlan="${this.handlers.viewPayerPlan}"
          .onClickPaymentId="${this.handlers.viewPayment}"
        ></neb-table-allocation-charges>
      </div>
    `;
  }

  __renderAllocatedChargesHeader() {
    const { id, title, description } = this.isPreview
      ? ELEMENTS.headerPreviewAllocatedCharges
      : ELEMENTS.headerAllocatedCharges;

    return html`
      <neb-header
        id="${id}"
        .label="${title}"
        .description="${description}"
      ></neb-header>
    `;
  }

  __renderAllocatedCharges() {
    if (
      !this.isPreview &&
      !isPartiallyOrFullyAllocated(this.__paymentModel[0])
    ) {
      return '';
    }

    return html`
      ${this.__renderAllocatedChargesHeader()}

      <div class="container-filter">
        <neb-date-picker
          id="${ELEMENTS.dateOfTransactionFrom.id}"
          name="dateOfTransactionFrom"
          class="field"
          label="Transaction From Date"
          helperText=" "
          .selectedDate="${this.itemFilters.dateOfTransactionFrom}"
          .isDateSelectable="${this.handlers.dateOfTransactionFromSelectable}"
          .onChange="${this.handlers.itemsFilterChange}"
          momentFlag
        ></neb-date-picker>

        <neb-date-picker
          id="${ELEMENTS.dateOfTransactionTo.id}"
          name="dateOfTransactionTo"
          class="field"
          label="Transaction To Date"
          helperText=" "
          .selectedDate="${this.itemFilters.dateOfTransactionTo}"
          .isDateSelectable="${this.handlers.dateOfTransactionToSelectable}"
          .onChange="${this.handlers.itemsFilterChange}"
          momentFlag
        ></neb-date-picker>

        <neb-select
          id="${ELEMENTS.selectPatient.id}"
          name="patient"
          class="field"
          label="Patient"
          .items="${this.patients}"
          .value="${this.itemFilters.patient}"
          .onChange="${this.handlers.itemsFilterChange}"
          ?disabled="${this.paymentDetail.patientId && !this.hasRelationships}"
        ></neb-select>

        ${this.__renderLocationsSelect()}
      </div>
      ${
        this.isPreview
          ? this.__renderPreviewAllocation()
          : this.__renderEditAllocation()
      }
      ${
        this.__hoverBarVisible
          ? html`
              <neb-horizontal-scroll
                id="${ELEMENTS.hoverHorizontalScrollBar.id}"
                .onChange="${this.handlers.hoverBarScroll}"
                .yOffset="${this.__isActionBarEnabled() ? 80 : 0}"
                .minWidth="${this.__minWidth}"
                .rectTop="${this.getBoundingClientRect().top}"
              ></neb-horizontal-scroll>
            `
          : ''
      }
    `;
  }

  __isEditingPreviewAllocations() {
    return (
      this.isPreview &&
      this.model &&
      this.model.items &&
      this.model.items.length &&
      this.__selectIndexes.some(index => index)
    );
  }

  __isActionBarEnabled() {
    return this.formService.isDirty || this.__isEditingPreviewAllocations();
  }

  renderActionBar() {
    if (this.__isActionBarEnabled()) {
      return html`
        <neb-action-bar
          id="${ELEMENTS.actionBar.id}"
          .onConfirm="${this.handlers.save}"
          .onCancel="${this.handlers.cancel}"
          confirmLabel="${this.isPreview ? 'Allocate' : this.confirmLabel}"
          cancelLabel="${this.cancelLabel}"
        ></neb-action-bar>
      `;
    }

    return '';
  }

  renderContent() {
    return html`
      ${this.__renderHeader()} ${this.__renderPaymentDetails()}
      ${this.__renderAllocatedCharges()}
    `;
  }
}

customElements.define('neb-form-payment-view', NebFormPaymentView);
