import { SCRUB_CODE } from '../../../packages/neb-lit-components/src/utils/claim-validation';
import { OVERLAY_KEYS } from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  DATE_OF_CURRENT_ILLNESS_QUALIFIER,
  OTHER_DATE_QUALIFIER,
} from '../../../packages/neb-utils/claims';

const getPatientCaseIdValue = patientCase =>
  patientCase.data ? patientCase.data.id : null;

const getInitialDateValue = initialTxDate => initialTxDate || null;

export const CLAIM_ERROR_DICT = {
  ITD_DOO: {
    errorCodes: [
      SCRUB_CODE.PL007,
      SCRUB_CODE.PL010,
      SCRUB_CODE.PL020,
      SCRUB_CODE.PL021,
    ],
    overlay: OVERLAY_KEYS.CLAIM_ERROR_ITD_DOO,
  },
  MANAGE_ENCOUNTER: {
    errorCodes: [
      SCRUB_CODE.CL001,
      SCRUB_CODE.CL002,
      SCRUB_CODE.CL003,
      SCRUB_CODE.CL004,
      SCRUB_CODE.CL005,
      SCRUB_CODE.CL006,
      SCRUB_CODE.CL007,
      SCRUB_CODE.CL008,
      SCRUB_CODE.PL012,
      SCRUB_CODE.PL013,
      SCRUB_CODE.PL015,
      SCRUB_CODE.PL016,
      SCRUB_CODE.PL031,
      SCRUB_CODE.PL032,
    ],
    overlay: OVERLAY_KEYS.CLAIM_ERROR_MANAGE_ENCOUNTER,
  },
  INSURANCE: {
    errorCodes: [
      SCRUB_CODE.PL006,
      SCRUB_CODE.PL009,
      SCRUB_CODE.PL018,
      SCRUB_CODE.PL023,
      SCRUB_CODE.PL017,
      SCRUB_CODE.PL019,
      SCRUB_CODE.PL023,
      SCRUB_CODE.PL024,
    ],
    overlay: OVERLAY_KEYS.CLAIM_ERROR_INSURANCE,
  },
  PATIENT_INFO: {
    errorCodes: [SCRUB_CODE.PL008],
    overlay: OVERLAY_KEYS.CLAIM_ERROR_PATIENT_INFO,
  },
  PAYER: {
    errorCodes: [SCRUB_CODE.PL011],
    overlay: OVERLAY_KEYS.CLAIM_ERROR_PAYER,
  },
};

export const formatClaimInfo = ({ claim, claimError, practiceInfo }) => {
  const { claimValidationErrors } = claim;
  const errorDescriptions = claimValidationErrors
    .filter(err => claimError.errorCodes.includes(err.code))
    .map(errDesc => errDesc.description);

  return {
    claimId: claim.id,
    claimNumber: claim.claimNumber,
    invoiceId: claim.invoiceId,
    patient: claim.patient,
    patientId: claim.patient.id,
    serviceDate: claim.dateOfService,
    lastUpdated: claim.lastUpdated,
    provider: claim.provider,
    payer: claim.primaryPayerAlias,
    caseId: claim.patientCaseId,
    dateOfOnset: claim.dateOfCurrentIllness,
    errorDescriptions,
    practiceInfo,
  };
};

export const buildClaimsDataToUpdate = ({
  claim,
  formModel,
  updateCaseDOO,
}) => {
  const dateOfCurrentIllnessQualifier = formModel.dateOfOnset
    ? DATE_OF_CURRENT_ILLNESS_QUALIFIER.dateOfCurrentIllness.value
    : null;

  const otherDateQualifier = formModel.initialTxDate
    ? Object.keys(OTHER_DATE_QUALIFIER).find(
        key => OTHER_DATE_QUALIFIER[key] === 'Initial Treatment',
      )
    : null;

  const claimDraft = {
    id: claim.id,
    dateOfCurrentIllnessQualifier,
    otherDateQualifier,
    dateOfCurrentIllness: formModel.dateOfOnset,
    otherDate: getInitialDateValue(formModel.initialTxDate),
    patientCaseId: getPatientCaseIdValue(formModel.patientCase),
  };

  const allEncounters = claim.claimCharges.map(charge => ({
    id: charge.encounterId,
    currentIllnessDate: formModel.dateOfOnset,
    currentIllnessGradual: !formModel.dateOfOnset,
  }));

  const uniqueEncounters = Array.from(
    new Set(allEncounters.map(encounter => encounter.id)),
  ).map(id => allEncounters.find(encounter => encounter.id === id));

  const lineItems = claim.claimCharges.map(charge => ({
    id: charge.lineItemId,
    encounterChargeId: charge.encounterChargeId,
    patientCaseId: getPatientCaseIdValue(formModel.patientCase),
    diagnosesPointers: charge.diagnosesPointers
      ? charge.diagnosesPointers.split(',')
      : [],
  }));

  const patientCase = {
    id: getPatientCaseIdValue(formModel.patientCase),
    onsetSymptomsDate: updateCaseDOO
      ? formModel.dateOfOnset
      : formModel.patientCase.data?.onsetSymptomsDate || null,
    initialTxDate: getInitialDateValue(formModel.initialTxDate),
  };

  return {
    lineItems,
    claimDrafts: [claimDraft],
    encounters: uniqueEncounters,
    patientCases: formModel.patientCase.data ? [patientCase] : [],
  };
};
