import { openPopup } from '@neb/popup';

import { openError } from '../../packages/neb-dialog/neb-banner-state';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../packages/neb-popup/src/renderer-keys';
import { store } from '../../packages/neb-redux/neb-redux-store';
import * as eraEobApi from '../api-clients/era-eob';

export const openERAEOBOverlay = async ({ item, fetchData = true }) => {
  let data;

  if (fetchData) {
    ({ data } = await eraEobApi.getDataById(item));
  } else {
    data = [item];
  }

  if (data.length) {
    await openOverlay(OVERLAY_KEYS.ERA_EOB_MANAGEMENT, {
      readonly: false,
      era: data[0],
    });

    return true;
  }
  store.dispatch(openError(`${item.type} not found.`));

  return false;
};

export const openERAEOBPopup = async ({
  associatedERAsAndEOBs,
  lineItemId,
  fetchData,
}) => {
  let data = [];

  if (fetchData) {
    data = await eraEobApi.getDataByLineItemId(lineItemId);
  } else {
    data = associatedERAsAndEOBs;
  }

  if (data.length) {
    const items = [...data].sort((a, b) => b.number - a.number);
    const response = await openPopup(POPUP_RENDER_KEYS.SELECT_ERA_EOB, {
      data: items,
    });

    if (response && response.selectedIndex !== undefined) {
      await openERAEOBOverlay({
        item: items[response.selectedIndex],
        fetchData: true,
      });

      return true;
    }
  } else store.dispatch(openError('ERAs/EOBs not found.'));
  return false;
};

export const viewERAsEOBs = async ({
  associatedERAsAndEOBs,
  lineItemId,
  fetchData = true,
}) => {
  let response = false;

  if (associatedERAsAndEOBs.length === 1) {
    const [associatedERAorEOB] = associatedERAsAndEOBs;
    response = await openERAEOBOverlay({ item: associatedERAorEOB, fetchData });
  } else {
    response = await openERAEOBPopup({
      associatedERAsAndEOBs,
      lineItemId,
      fetchData,
    });
  }

  return response;
};

export const ERA_ACTIONS = Object.freeze({
  ERA_POSTED: 'ERA_POSTED',
  MERGE_LINE_ITEMS: 'MERGE_LINE_ITEMS',
  POST_ERA: 'POST_ERA',
  RESET_STATUS: 'RESET_STATUS',
  MATCH_CHARGE: 'MATCH_CHARGE',
  MANUAL_POST: 'MANUAL_POST',
  MARK_AS_DENIED: 'MARK_AS_DENIED',
  REVERT_CLAIM: 'REVERT_CLAIM',
  MATCH_CLAIM: 'MATCH_CLAIM',
});
