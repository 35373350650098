import { datadogRum } from '@datadog/browser-rum';

const TENANT_FIELDS = [
  'id',
  'name',
  'salesforceId',
  'tier',
  'timezone',
  'dstDisabled',
];

const USER_FIELDS = ['id', 'name'];

const filterFields = (obj, fields) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (fields.includes(key)) {
      acc[key] = value;
    }

    return acc;
  }, {});

const getDDRetention = (ddRetention, defaultSessionSampleRate) => {
  if (ddRetention === null) {
    return defaultSessionSampleRate;
  }

  const ddRetentionNumber = parseInt(ddRetention, 10);

  if (ddRetentionNumber >= 0 && ddRetentionNumber <= 100) {
    return ddRetentionNumber;
  }
  return defaultSessionSampleRate;
};

export const initRum = (ddRetention = null) => {
  const {
    NEB_ENV: {
      DATADOG_APPLICATION_ID,
      DATADOG_CLIENT_TOKEN,
      DATADOG_SERVICE_NAME,
      DATADOG_VERSION,
      DATADOG_ENV,
      DATADOG_SAMPLE_RATE,
    } = {},
  } = window;

  if (DATADOG_APPLICATION_ID) {
    const defaultSessionSampleRate = parseInt(DATADOG_SAMPLE_RATE, 10) || 0;

    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      service: DATADOG_SERVICE_NAME,
      version: DATADOG_VERSION,
      env: DATADOG_ENV,
      sessionReplaySampleRate: 100,
      sessionSampleRate: getDDRetention(ddRetention, defaultSessionSampleRate),
      startSessionReplayRecordingManually: true,
      trackInteractions: true,
      trackFrustrations: true,
      trackLongTasks: true,
      trackResources: true,
      allowedTracingOrigins: [window.location.origin],
      traceSampleRate: 0,
    });
  }
};

export const datadogRumStartView = name => {
  datadogRum.startView({
    name,
  });
};

export const datadogRumAddAction = name => {
  datadogRum.addAction(name);
};

export const setRumTenant = tenant => {
  const formattedTenant = filterFields(tenant, TENANT_FIELDS);
  const { features } = tenant;

  if (features && features.includes('enable-rum-session-replay')) {
    datadogRum.startSessionReplayRecording();
  }

  datadogRum.setGlobalContext('tenant', formattedTenant);
};

export const setRumUser = user => {
  const formattedUser = filterFields(user, USER_FIELDS);

  datadogRum.setUser(formattedUser);
};

export const clearRumUser = () => datadogRum.clearUser();
