import deepEqual from 'fast-deep-equal';

import { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const apiClient = new ApiClientV2({ microservice: 'billing' });

export const allocatePayment = async (paymentId, lineItems, version = 3) => {
  const path =
    version === 3
      ? '/api/v3/tenants/:tenantId/payments/:paymentId/allocations'
      : '/api/v4/tenants/:tenantId/payments/:paymentId/allocations';
  await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    replacements: { paymentId },
    body: JSON.stringify({ lineItems, hasAutoUnallocate: true }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
  });
};

export const deleteAllocation = async (paymentId, lineItems) => {
  await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/payments/:paymentId/allocations',
    replacements: { paymentId },
    body: JSON.stringify({ lineItems }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.DELETE,
  });
};

export const getPreviewCharges = async (
  paymentId,
  queryParams,
  optOutLoadingIndicator = false,
) => {
  const res = await apiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/payments/:paymentId/allocations/preview',
    replacements: { paymentId },
    ...(queryParams && { queryParams }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
  });

  return res.data;
};

export const getAllocatedCharges = async (
  paymentId,
  queryParams,
  optOutLoadingIndicator = false,
) => {
  const res = await apiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/payments/:paymentId/allocations',
    replacements: { paymentId },
    ...(queryParams && { queryParams }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
  });

  return res.data;
};

export const getClaimPaymentsWithAllocation = ({ claimId, payerPlanId }) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/claims/:claimId/payments-with-allocation',
    replacements: { claimId },
    queryParams: { payerPlanId },
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const recoupAllocation = (
  toPaymentId,
  paymentId,
  allocationId,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    path:
      '/api/v1/tenants/:tenantId/payments/:paymentId/allocations/:allocationId/recoup',
    replacements: { paymentId, allocationId },
    headers: {
      'Content-Type': 'application/json',
    },
    body: { toPaymentId },
    method: Method.POST,
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });

export const reverseClaim = (
  toPaymentId,
  paymentId,
  claimId,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    path:
      '/api/v1/tenants/:tenantId/payments/:paymentId/claims/:claimId/reverse',
    replacements: { paymentId, claimId },
    headers: {
      'Content-Type': 'application/json',
    },
    body: { toPaymentId },
    method: Method.POST,
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });

export const removeRecoupHistory = (
  allocationId,
  paymentId,
  recoupId,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    path:
      '/api/v1/tenants/:tenantId/payments/:paymentId/allocations/:allocationId/recoup/:recoupId',
    replacements: { allocationId, paymentId, recoupId },
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.DELETE,
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });

const formatAllocation = li => ({
  id: li.id,
  allowedAmount: li.allowedAmount,
  debits: li.lineItemDebits.map(
    ({
      debit: { id, allocated, amount, payerId },
      codePaymentId,
      patientInsuranceId,
    }) => ({
      ...(id && { id }),
      allocated: allocated || 0,
      amount: amount || 0,
      payerId,
      codePaymentId: codePaymentId || null,
      patientInsuranceId: patientInsuranceId || null,
    }),
  ),
  adjustments: li.adjustments
    .filter(a => a.codeId && a.amount)
    .map(({ id, codeId, amount }) => ({
      ...(id && { id }),
      codeId,
      amount,
    })),
});

const formatAllocationDetails = lineItems =>
  lineItems.map(li => formatAllocation(li));

export const getChangedLineItemsForAllocation = (
  previousLineItems,
  lineItems,
  isPreview,
  selectIndexes,
) => {
  const previousAllocations = formatAllocationDetails(previousLineItems);
  const allocations = formatAllocationDetails(lineItems);

  if (isPreview) return allocations.filter((_, index) => selectIndexes[index]);

  return allocations.reduce((memo, item) => {
    const previous = previousAllocations.find(prev => prev.id === item.id);

    if (previous && !deepEqual(previous, item)) {
      memo.push(item);
    }

    return memo;
  }, []);
};

const formatAllocationV2 = li => ({
  id: li.id,
  allowedAmount: li.allowedAmount,
  debits: li.debits.map(
    ({
      id,
      allocated,
      amount,
      payerId,
      codePaymentId,
      patientInsuranceId,
    }) => ({
      ...(id && { id }),
      allocated: allocated || 0,
      amount: amount || 0,
      payerId,
      codePaymentId: codePaymentId || null,
      patientInsuranceId: patientInsuranceId || null,
    }),
  ),
  adjustments: li.adjustments
    .filter(a => a.codeId && a.amount)
    .map(({ id, codeId, amount }) => ({
      ...(id && { id }),
      codeId,
      amount,
    })),
});

const formatAllocationDetailsV2 = lineItems =>
  lineItems.map(li => formatAllocationV2(li));

export const getChangedLineItemsForAllocationV2 = (
  previousLineItems,
  lineItems,
  isPreview,
  selectIndexes,
) => {
  const previousAllocations = formatAllocationDetailsV2(previousLineItems);
  const allocations = formatAllocationDetailsV2(lineItems);

  if (isPreview) return allocations.filter((_, index) => selectIndexes[index]);

  return allocations.reduce((memo, item) => {
    const previous = previousAllocations.find(prev => prev.id === item.id);

    if (previous && !deepEqual(previous, item)) {
      memo.push(item);
    }

    return memo;
  }, []);
};
